import { S3SyncFiles } from "./types";
import { DandaError } from "../dashboards/types";

export class ProjsSync {
  public static URL = "http://192.168.1.11:8888";
  /**
   * Returns a list of files in the redshift sync S3 bucket
   * @returns {Promise<S3SyncFiles>}
   */
  public async ListS3Files(): Promise<S3SyncFiles> {
    const url = `${ProjsSync.URL}/api/v1/projs-sync/files`;
    const res = await fetch(url, { credentials: "include" });
    if (res.status !== 200) {
      const body = await res.text();
      throw new DandaError(res.status, body);
    }
    return await res.json();
  }

  public async QuerySync(q: unknown): Promise<unknown> {
    const url = `${ProjsSync.URL}/api/v1/projs-sync/query?q=${q}`;
    const res = await fetch(url, { credentials: "include" });
    if (res.status !== 200) {
      const body = await res.text();
      throw new DandaError(res.status, body);
    }
    return await res.json();
  }
}
