










import SyncList from "./SyncList.vue";
import SyncQuery from "./SyncQuery.vue";

import { Vue, Component } from "vue-property-decorator";
//import Loader from "@/components/Loader.vue";
//import { Vue, Component, Watch } from "vue-property-decorator";
//import { ProjsSyncService } from "@/service/projssync";
//import { S3SyncFile, DandaError } from "@/service/projssync/types";

@Component({ components: { SyncList, SyncQuery } })
export default class ProjsSync extends Vue {}
