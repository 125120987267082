











































































import { Vue, Prop, Component } from "vue-property-decorator";
import { User as DandaUser } from "@/service/dashboards/danda";

@Component
export default class User extends Vue {
  @Prop({ required: true }) user!: DandaUser;

  logout(): void {
    this.$danda.logout();
    this.$router.push("/logout");
  }
}
