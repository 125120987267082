










import { Vue, Component } from "vue-property-decorator";

@Component
export default class Logout extends Vue {
  private logout = false;
  async created(): Promise<void> {
    try {
      await this.$danda.logout(); // weird
      this.logout = true;
    } catch (e) {
      // some error
    }
    setTimeout(() => this.$router.push("/"), 1000);
  }
}
