














































import { Vue, Component } from "vue-property-decorator";
import { ProjsSyncService } from "@/service/projssync";
import { S3SyncFile, DandaError } from "@/service/projssync/types";

@Component
export default class SyncList extends Vue {
  private files?: S3SyncFile[];
  private loading = false;
  private autoRefresh = 0;
  private lastRefresh?: Date | null = null;

  private refreshTimer?: number;

  async created(): Promise<void> {
    await this.refreshFiles();
    this.updateRefresh();
  }

  sortedFiles(): S3SyncFile[] {
    if (!this.files) {
      return [];
    }
    return this.files.sort((a, b) => {
      return b.count - a.count;
    });
  }

  async refreshFiles(): Promise<void> {
    this.loading = true;
    try {
      const res = await ProjsSyncService.ListS3Files();
      this.files = res.results;
      this.lastRefresh = new Date();
      // Load S3 project data sync
    } catch (e) {
      if (e instanceof DandaError) {
        this.$toasted.error(e.message);
      }
    } finally {
      this.loading = false;
    }
  }
  onDestroy(): void {
    console.log("Destroying 2");
    clearInterval(this.refreshTimer);
  }
  updateRefresh(): void {
    if (this.autoRefresh != 0) {
      console.log("updateRefresh", this.autoRefresh);
      this.refreshTimer = setInterval(() => {
        this.refreshFiles();
      }, this.autoRefresh * 1000);
    } else {
      clearInterval(this.refreshTimer);
    }
  }
}
