















import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Component as VueComponent } from "vue";

@Component({
  components: {
    Loading: Loading as VueComponent
  }
})
export default class extends Vue {
  @Prop({ default: false }) isFullPage!: boolean;
  @Prop({ default: false }) active!: boolean;
  @Prop({ default: 100, type: Number }) width!: number;
  @Prop({ default: 100, type: Number }) height!: number;
}
