import {
  DandaError,
  User,
  QSEmbedURL,
  QSDashboardResponse,
  S3ObjectsResponse
} from "./types";
export * from "./types";

/*
import * from "./types";
import {
  User,
  QSEmbedURL,
  QSDashboardResponse,
  S3ObjectsResponse,
  DandaError
} from "./types";
*/

export class Danda {
  public static URL = "https://dashboards.teamworkops.com";

  /**
   * Returns user information.
   * @returns the User object with logged user information
   */
  public async me(): Promise<User> {
    const url = `${Danda.URL}/api/v1/me`;
    const res = await fetch(url, { credentials: "include" });
    if (res.status !== 200) {
      const body = await res.text();
      throw new DandaError(res.status, body); // need better error handling
    }

    return await res.json();
  }

  /**
   * Returns quicksight embedURL
   * @param dashboardName - name of dashboard to return
   */
  public async GetQSEmbedURL(dashboardName: string): Promise<QSEmbedURL> {
    const url = `${Danda.URL}/api/v1/quicksight/${dashboardName}/embedurl`;
    const res = await fetch(url, { credentials: "include" });
    if (res.status !== 200) {
      const body = await res.text();
      throw new DandaError(res.status, body); // need better error handling
    }

    return await res.json();
  }

  /**
   * Returns a list of quicksight dashboards
   */
  public async ListQSDashboards(): Promise<QSDashboardResponse> {
    const url = `${Danda.URL}/api/v1/quicksight`;
    const res = await fetch(url, { credentials: "include" });
    if (res.status !== 200) {
      const body = await res.text();
      throw new DandaError(res.status, body);
    }

    return await res.json();
  }

  public async ListS3Objects(): Promise<S3ObjectsResponse> {
    const url = `${Danda.URL}/api/v1/s3`;
    const res = await fetch(url, { credentials: "include" });
    if (res.status !== 200) {
      const body = await res.text();
      throw new DandaError(res.status, body);
    }
    return await res.json();
  }

  public async logout(): Promise<string> {
    const url = `${Danda.URL}/api/v1/logout`;
    const res = await fetch(url, { credentials: "include" });
    if (res.status !== 200) {
      const body = await res.text();
      throw new DandaError(res.status, body); // need better error handling
    }
    return await res.text();
  }
}
