





import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import { embedDashboard } from 'amazon-quicksight-embedding-sdk'

@Component
export default class Quicksight extends Vue {
  @Prop({ required: true }) embedURL!:string

  private dashboard: unknown

  @Watch('embedURL')
  embedURLChanged (): void {
    this.updateDashboard()
  }

  mounted (): void {
    this.updateDashboard()
  }

  updateDashboard (): void {
    const opt = {
      url: this.embedURL,
      container: this.$el as HTMLElement,
      width: '100%',
      height: '100%',
      footerPaddingEnabled: true,
      loadCallback: () => { this.$emit('loaded', true) },
      errorCallback: (e: unknown) => { this.$emit('error', e) }
    }
    this.dashboard = embedDashboard(opt)
  }
}
