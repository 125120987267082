import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Auth from "../views/Auth.vue";
import Logout from "../views/Logout.vue";
import QSDashboard from "../views/QSDashboard.vue";
import S3Analysis from "../views/S3Analysis.vue";
import Home from "../views/Home.vue";
import ProjsSync from "../views/ProjsSync/ProjsSync.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // Everything public goes here
  {
    path: "/logout",
    name: "Logout",
    component: Logout
  },
  // Everything authenticated goes here and will be rendered within Auth area
  {
    path: "/",
    component: Auth,
    children: [
      {
        path: "/",
        name: "home",
        component: Home
      },
      {
        // prefix with a q just for quicksight dashboards
        path: "/q/:dashboardName",
        name: "quicksightDashboard",
        component: QSDashboard
      },
      {
        path: "/s/:key",
        name: "staticAnalysis",
        component: S3Analysis
      },
      {
        path: "/projs-sync",
        name: "projectSync",
        component: ProjsSync
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
