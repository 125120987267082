























import { Vue, Component } from "vue-property-decorator";
import { ProjsSyncService } from "@/service/projssync";

@Component
export default class SyncQuery extends Vue {
  private loading = false;
  private query = "";
  private data: unknown = null;

  async updateQuery(e: Event): Promise<void> {
    e.preventDefault();

    try {
      this.loading = true;
      const res = await ProjsSyncService.QuerySync(this.query);

      this.data = (res as Record<string, unknown>).results;
    } catch (e) {
      if (e instanceof Error) {
        this.$toasted.error(e.message);
      }
    } finally {
      this.loading = false;
      this.query = "";
    }
  }
}
