import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueToasted from "vue-toasted";
import Loader from "./components/Loader.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import DandaPlugin from "./service/dashboards";

Vue.config.productionTip = true;

Vue.use(DandaPlugin);
Vue.use(VueToasted, { position: "bottom-right" });
Vue.component("Loader", Loader);

Vue.filter("formatBytes", function(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const size = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  return `${size} ${sizes[i]}`;
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
