










import { Vue, Component } from "vue-property-decorator";
import { Danda, User, DandaError } from "@/service/dashboards/danda";
import UserBar from "@/components/UserBar.vue";

@Component({
  components: { UserBar }
})
export default class Auth extends Vue {
  private user: User | null = null;
  private loading = true;

  async mounted(): Promise<void> {
    try {
      this.loading = true;
      this.user = await this.$danda.me();
    } catch (e) {
      if (e instanceof DandaError) {
        if (e.status === 401) {
          const urlParams = new URLSearchParams({
            client_id: "bc90939a0dae710d6bb4cad8ed1f909edc23f65b",
            redirect_uri: `${Danda.URL}/api/v1/auth`,
            state: window.location.href
          }).toString();

          const url = `https://www.teamwork.com/launchpad/login?${urlParams}`;
          window.location.replace(url);
          return;
        }
        this.$toasted.error("error" + e.message);
        return;
      }
      this.$toasted.error("error" + e);
    } finally {
      this.loading = false;
    }
  }
}
