













import { Vue, Component } from "vue-property-decorator";
import Quicksight from "@/components/Quicksight.vue";

@Component({
  components: {
    Quicksight
  }
})
export default class Dashboard extends Vue {
  // vuex global state for dashboard
  private dashboard = {};
  private loading = true;

  async created(): Promise<void> {
    try {
      this.loading = true;
      this.dashboard = await this.$danda.GetQSEmbedURL(
        this.$route.params.dashboardName
      );
    } catch (e) {
      this.error(e);
    } finally {
      // In case error callback isn't triggered
      // https://github.com/awslabs/amazon-quicksight-embedding-sdk/issues/11
      setTimeout(() => (this.loading = false), 3000);
    }
  }

  loaded(): void {
    this.loading = false;
  }

  error(e: unknown): void {
    this.loaded();
    this.$toasted.error(e as string);
  }
}
