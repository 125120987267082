





















































































































































import { Component, Vue } from "vue-property-decorator";
import { QSDashboard, S3Object } from "@/service/dashboards/danda";
import format from "date-fns/format";

@Component
export default class Home extends Vue {
  private loading = true;
  private dashboards: QSDashboard[] = [];
  private s3analysis: S3Object[] = [];

  async created(): Promise<void> {
    try {
      this.loading = true;
      const dashboardRes = await this.$danda.ListQSDashboards();
      this.dashboards = dashboardRes.dashboards;
      const s3objectsRes = await this.$danda.ListS3Objects();
      console.log(s3objectsRes);
      this.s3analysis = s3objectsRes.items;
    } catch (e) {
      this.error(e);
    } finally {
      this.loaded();
    }
  }

  // This will be temporary until we implement in backend to list S3 static analysis
  get staticAnalysis(): unknown[] {
    return this.s3analysis.map(q => {
      return {
        ...q,
        url: `/s/${q.key}`
      };
    });
  }

  get wip(): unknown[] {
    return this.dashboards
      .filter(d => d.name.match(/(^|[^\w])WIP([^\w]|$)/i))
      .map(q => {
        return {
          ...q,
          name: q.name.replace(/(^|[^\w])*WIP([^\w]|$)*/i, ""),
          url: `/q/${q.id}`
        };
      });
  }

  get beta(): unknown[] {
    return this.dashboards
      .filter(d => d.name.match(/(^|[^\w])BETA([^\w]|$)/i))
      .map(q => {
        return {
          ...q,
          name: q.name.replace(/(^|[^\w])*BETA([^\w]|$)*/i, ""),
          url: `/q/${q.id}`
        };
      });
  }

  get prod(): unknown[] {
    return this.dashboards
      .filter(d => d.name.match(/(^|[^\w])LIVE([^\w]|$)|PQL Dashboard/i)) // eventually remove PQL when it is renamed with a LIVE prefix
      .map(q => {
        return {
          ...q,
          name: q.name.replace(/(^|[^\w])*LIVE([^\w]|$)*/i, ""),
          url: `/q/${q.id}`
        };
      });
  }

  dateFormat(ad: string): string {
    if (!ad) return "-";
    try {
      const d = new Date(ad);
      return format(d, "dd MMM yyyy HH:mm");
    } catch (e) {
      return "-";
    }
  }

  loaded(): void {
    this.loading = false;
  }

  error(e: unknown): void {
    this.loaded();
    this.$toasted.error(e as string);
  }
}
