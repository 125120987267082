








import { Vue, Component } from "vue-property-decorator";
import { Danda } from "@/service/dashboards/danda";
import Quicksight from "@/components/Quicksight.vue";

@Component({
  components: {
    Quicksight
  }
})
export default class Dashboard extends Vue {
  // vuex global state for dashboard
  private analysis = {};
  private loading = true;

  async mounted(): Promise<void> {
    try {
      this.loading = true;
      const analysis = this.$route.params.key;
      const c = this.$refs.content as HTMLElement;
      c.setAttribute("src", `${Danda.URL}/api/v1/s3/${analysis}`);
    } catch (e) {
      this.error(e);
    } finally {
      // In case error callback isn't triggered
      // https://github.com/awslabs/amazon-quicksight-embedding-sdk/issues/11
      setTimeout(() => (this.loading = false), 3000);
    }
  }

  loaded(): void {
    this.loading = false;
  }

  error(e: unknown): void {
    this.loaded();
    this.$toasted.error(e as string);
  }
}
