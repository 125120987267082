export class DandaError extends Error {
  status!: number;
  constructor(status: number, message?: string) {
    super(message);
    this.status = status;
  }
}

export interface User {
  avatarURL: string;
  email: string;
}

export interface QSEmbedURL {
  embedURL: string;
  dashboardID: string;
}

export interface QSDashboard {
  id: string;
  name: string;
  updatedAt: string;
  publishedAt: string;
}

export interface QSDashboardResponse {
  dashboards: Array<QSDashboard>;
}

export interface S3Object {
  name: string;
  key: string;
}

export interface S3ObjectsResponse {
  items: Array<S3Object>;
}
